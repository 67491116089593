<template>
    <div>
        <table>
            <thead>
                <tr>
                    <th class="col1">id</th>
                    <th class="col2">name</th>
                    <th class="col3">EnumData</th>
                    <th class="col4">EnumDataShow</th>
                    <th class="col4">EnumDataShow</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in TestData" v-bind:key="item.ID">
                    <td>{{item.ID}}</td>
                    <td>{{item.IDCard}}</td>
                    <td>{{item.EnumData}}</td>
                    <td>{{ShowEnum(item.EnumData)}}</td>
                    <td>{{ShowEnum2(item.EnumData)}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<style scoped>
    th {
        text-align: center;
        width: 80px;
    }

    td {
        text-align: center;
    }

    .col2 {
        width: 160px;
    }
</style>
<script>
    import http from "@/Plugin/Http.js";
    export default {
        name: "EnumDemo",
        data() {
            return {
                jsondata: [],//后台返回的枚举为数组。元素结构为{Key:<枚举编号>,Name:<枚举名称>}
                TestData: []
            };
        },
        methods: {//方法
            getData: function () {//获取测试数据
                var Self = this;
                var op2 = {
                    url: "/Test/GetTestData",
                    OnSuccess: function (res) {
                        Self.TestData = res.data;
                    }
                }
                http.Post(op2);
            },
            getData2: function () {//获取枚举信息
                var Self = this;
                var op = {
                    url: "/Enum",
                    data: {
                        AssemblyName: "Welcome.MarketingModule",//程序集名称
                        TypeName: "Welcome.MarketingModule.Enumerate.EnumFinishStatus"//枚举类型名称。要带上命名空间的全称
                    },
                    OnSuccess: function (response) {
                        Self.jsondata = response.data
                    }
                }
                http.Post(op);
            },
            ShowEnum: function (Key) {//枚举从0开始，且连续，可以使用此方法，效率高
                var Self = this;
                if (Self.jsondata[Key])
                    return Self.jsondata[Key].Name
                else
                    return "";
            },
            ShowEnum2: function (Key) {//枚举不是从0开始，或不连续，可以使用此方法
                var Self = this;
                var item = Self.jsondata.find(function (item) {
                    return item.ID == Key;
                });
                if (item)
                    return item.Name
                else
                    return "";
            }
        },
        mounted: function () {//生命周期，组装完成
            this.getData();
            this.getData2();
        },
    };
</script> 